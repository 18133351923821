@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@100;400&display=swap');


body {
  margin: 0;
  font-weight: 600;
  color: #fefefe;
  font-family: "Cairo",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

iframe#game_frame {
  border: none;
}
/* hide opera popout */
div#detach-button-host {
  display: none !important;
}

/* hide e3ds bottom panel object */
div#e3ds_bottom_panelObj {
  display: none !important;
}
div.e3ds_bottom_panel_controls {
  display: none !important;
}
div.e3ds_control_toggle {
  display: none !important;
}